<template>
	<div class="h-10"></div>
	<div class="h-10"></div>
	<div class="h-10"></div>
	<div class="fixed-bottom">
		<a :class="{ oPage: $route.name === 'myzq' }" @click="movePage('/myzq')"
			>My Z.Q</a
		>
		<a
			:class="{ oPage: $route.name === 'zeronft' }"
			@click="movePage('/zeronft')"
			>ZeroNFT</a
		>
		<a
			:class="{
				oPage: $route.name === 'onft' || $route.name === 'onft-detail',
			}"
			@click="movePage('/onft')"
			>PlayNFT</a
		>
		<a
			:class="{ oPage: $route.name === 'mywallet' }"
			@click="movePage('/mywallet')"
			>My<br />Point</a
		>
		<a :class="{ oPage: $route.name === 'market' }" @click="movePage('/market')"
			>Eco<br />Market</a
		>
		<a
			:class="{
				oPage: $route.name === 'challenge',
			}"
			@click="movePage('/Challenge')"
			>Challenge</a
		>
	</div>

	<Modal :visible="isPopup" @hide="closeModal" title="서비스 준비중" />
</template>

<script lang="ts" setup>
import router from '@/router';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

const vuexStore = useStore();

const isPopup = ref(false);

const closeModal = () => {
	isPopup.value = false;
};

const movePage = (page: string) => {
	router.push(page);
};

// const movePage = (page: string) => {
// 	if (page === '/Challenge') {
// 		vuexStore.state.popupType = 'message';
// 		isPopup.value = true;
// 	} else {
// 		router.push(page);
// 	}
// };
</script>

<style scoped lang="scss"></style>
