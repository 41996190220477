<template>
	<Header
		v-if="
			$route.name !== 'home' &&
			$route.name !== 'terms' &&
			$route.name !== 'Tutorial'
		"
	/>
	<div id="appMain" class="wrap">
		<router-view />
		<div v-if="$route.name !== 'Tutorial'" class="h-20"></div>
	</div>
	<Footer
		v-if="
			$route.name !== 'home' &&
			$route.name !== 'terms' &&
			$route.name !== 'Tutorial'
		"
	/>
	<LoadingSpinner v-if="store.state.isLoading"></LoadingSpinner>
	<LoadingSpinner v-if="store.state.isRouterLoading"></LoadingSpinner>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import store from '@/store';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import Header from '@/components/common/HeaderView.vue';
import Footer from '@/components/common/FooterView.vue';
import { onMounted } from 'vue';

const vuexStore = useStore();
const bannerList = store.getters['auth/getBannerList'];
const nftList = store.getters['auth/getNftList'];

onMounted(async () => {
	if (!bannerList) {
		await vuexStore.dispatch('auth/getBannerList', store);
	}

	if (!nftList) {
		await vuexStore.dispatch('auth/getNftList', store);
	}
});
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

.wrap {
	height: 100%;
	overflow-y: auto;
	overflow-y: overlay;
}
</style>
