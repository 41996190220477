export async function createDIDWithDocument(uniqueId: string) {
  if (window.flutter_inappwebview) {
    try {
      const res = await window.flutter_inappwebview.callHandler(
        'createDIDWithDocument',
        {
          uniqueId,
        }
      );
      const json = JSON.parse(res);
      const did = json.did;
      const didDocument = json.didDocument;
      const privateKey = json.privateKey;
      const publicKey = json.publicKey;
      if (did && didDocument) {
        return { did, didDocument, privateKey, publicKey, error: null };
      } else {
        throw new Error(
          'Failed to create DID with document, received: ' +
            JSON.stringify(json)
        );
      }
    } catch (error) {
      throw new Error(
        'Failed to create DID with document, received: ' + JSON.stringify(error)
      );
    }
  }
  throw new Error('This function is only available on Android and iOS');
}
