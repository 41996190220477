import { Commit } from 'vuex';
import http from '@/api/http';

interface Category {
	idx: number;
	name: string;
}

interface Product {
	idx: number;
	title: string;
	price: number;
}

export default {
	state: {
		selectedCategoryIdx: -1, // 초기값: 전체 카테고리 선택
		selectedProduct: null as Product | null,
		products: [],
		categories: [],
		marketProductInfo: localStorage.getItem('marketProductInfo')
			? JSON.parse(localStorage.getItem('marketProductInfo') || '{}') // Added '|| '{}'' to ensure a string is passed
			: {},
	},
	mutations: {
		setSelectedCategoryIdx(state: any, idx: number) {
			state.selectedCategoryIdx = idx;
		},
		setSelectedProduct(state: any, product: Product) {
			// 추가된 부분
			state.selectedProduct = product;
		},
		setProducts(state: any, products: any[]) {
			state.products = products;
		},
		setCategories(state: any, categories: Category[]) {
			state.categories = categories;
		},
		SET_MARKETPRODUCTINFO(state: any, payload: any) {
			state.marketProductInfo = payload;
			localStorage.setItem('marketProductInfo', JSON.stringify(payload));
		},
	},
	getters: {
		selectedCategoryIdx: (state: any) => state.selectedCategoryIdx,
		filteredProducts: (state: any) => {
			const filtered =
				state.selectedCategoryIdx === -1
					? state.products
					: state.products.filter(
							(product: any) =>
								product.categoryIdx === state.selectedCategoryIdx,
					  );
			return filtered;
		},
		marketProductIdx: (state: any) => state.marketProductInfo.idx,
		marketProductPrice: (state: any) => state.marketProductInfo.price,
	},
	actions: {
		async fetchProducts({ commit }: { commit: Commit }) {
			try {
				const res = await http.get(`/api/market/products`);

				commit('setProducts', res.data.data);
			} catch (error) {
				console.error('Error fetching market products:', error);
			}
		},

		async fetchCategories({ commit }: { commit: Commit }) {
			try {
				const categoriesRes = await http.get(`/api/market/category`);
				const categories = [
					{ idx: -1, name: '전체' },
					...categoriesRes.data.data,
				];
				commit('setCategories', categories);
			} catch (error) {
				console.error('Error fetching market categories:', error);
			}
		},

		async marketProductInfo(context: any, data: any) {
			context.commit('SET_MARKETPRODUCTINFO', data);
		},
	},
};
