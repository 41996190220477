export default {
	namespaced: true,
	state: {
		wmu_data: [],
		user_vote: 0,
	},
	getters: {
		wmuDataLength: (state: Nullable) => state.wmu_data.length,
	},
	mutations: {
		SET_WMU_CONTENTS(state: Nullable, payload: Nullable) {
			state.wmu_data = payload;
		},
		SET_WMU_NO7(state: Nullable) {
			// const idx7Object = {idx: 7}
			// const idx30Object = {idx: 30}
			// state.wmu_data.splice(6, 0, idx7Object)
			// const length = state.wmu_data.length;
			// state.wmu_data.splice(length, 0, idx30Object)
		},
		SET_WMU_USERVOTE(state: Nullable, payload: Nullable) {
			state.user_vote = payload;
		},
	},
	actions: {
		wmuContents(context: Nullable, data: Nullable) {
			context.commit('SET_WMU_CONTENTS', data);
		},
		wmuUserVote(context: Nullable, data: Nullable) {
			context.commit('SET_WMU_USERVOTE', data);
		},
	},
};
