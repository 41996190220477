<template>
	<el-dropdown class="locale" trigger="click">
		<div class="login-box">
			<div>
				<img class="my-img" src="./../../assets/images/icons8.png" />
			</div>
		</div>
		<template #dropdown>
			<el-dropdown-menu class="my-drop-menu">
				<el-dropdown-item
					v-for="item in logouts"
					:key="item.name"
					class="my-drop-menu-item"
					@click="forceLogout()"
					>{{ item.label }}</el-dropdown-item
				>
			</el-dropdown-menu>
		</template>
	</el-dropdown>
</template>

<script setup lang="ts">
import { logouts } from '@/config/constants/logout';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const router = useRouter();
const store = useStore();

const forceLogout = () => {
	store.commit('auth/setClearToken');

	if (store.state.showMode === 'webview') {
		window.Java.jsSignOut();

		router.push({ name: 'home', query: { showMode: 'webview', isLogin: 0 } });
	} else {
		// router.push('/login');
		window.location.href = '/';
	}
};
</script>

<style lang="scss">
.locale {
	.icon {
		cursor: pointer;
		width: 36px;
		height: 36px;

		@media screen and (max-width: 1240px) {
			width: 36px;
			height: 36px;
		}
	}
}
.my-img {
	width: 36px;
	height: 36px;
}
</style>
